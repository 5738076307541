<template>
  <div class="expande-horizontal pa-3 wrap">
    <div class="expande-horizontal mb-3 mt-3">
      <span style="font-size: 18pt;" class="fonte-bold white--text mr-2">
        Minhas Contas Bancárias
      </span>
      <v-btn
        @click="openModalViewAccountBank()"
        class="white--text fonte-bold"
        small
        color="yellow"
      >
        <span class="black--text">
          Nova Conta +
        </span>
      </v-btn>
    </div>
    <v-list color="transparent" class="expande-horizontal column" three-line>
      <template v-for="(item, i) in getAccountBanks">
        <v-list-item
          class="list-item"
          @click="openModalViewAccountBank(item)"
          :key="item._id"
        >
          <v-list-item-content>
            <v-list-item-title class="fonte white--text">
              {{ item.bank }} - {{ item.pix_type }}
            </v-list-item-title>
            <v-list-item-subtitle class="fonte white--text">
              {{ item.info }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="i"></v-divider>
      </template>
    </v-list>
    <ModalViewAccountBank />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalViewAccountBank from "./modalViewAccountBank.vue";

export default {
  components: {
    ModalViewAccountBank
  },
  computed: {
    ...mapGetters(["getAccountBanks"])
  },
  methods: {
    ...mapActions(["openModalViewAccountBank", "listAccountBanks"])
  },
  created() {
    this.listAccountBanks();
  }
};
</script>

<style>
.list-item {
  border: 1px solid #666;
  width: 100%;
  border-radius: 6px;
  background: #555;
  margin-bottom: 6px;
}
</style>
