<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="getModalViewAccountBank"
  >
    <v-card dark class="expande-horizontal wrap">
      <div class="expande-horizontal px-6 py-3">
        <v-btn dark @click="closeModalViewAccountBank" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt;" class="fonte white--text">
          Conta Bancária
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="closeModalViewAccountBank">
          <v-icon color="red darken-3">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Banco
              </span>
              <v-text-field
                autofocus
                v-model="getAccountBank.bank"
                :rules="[v=> !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                TIPO DE CHAVE
              </span>
              <v-text-field
                autofocus
                v-model="getAccountBank.pix_type"
                :rules="[v=> !!v || 'preencha aqui']"
                dense
                placeholder="ex: CPF, EMAIL..."
                solo-inverted
                flat
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                INFORMAÇÕES DA CONTA
              </span>
              <v-textarea
                autofocus
                v-model="getAccountBank.info"
                :rules="[v=> !!v || 'preencha aqui']"
                outlined
                :color="$theme.primary"
              ></v-textarea>
            </v-flex>
            
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="validate" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getModalViewAccountBank", "getAccountBank"])
  },
  methods: {
    ...mapActions(["createAccountBank", "updateAccountBank", "closeModalViewAccountBank"]),
    validate() {
      if (this.$refs.form.validate()) {
        if(this.getAccountBank._id) {
            this.updateAccountBank()
        } else {
            this.createAccountBank()
        }
      }
    }
  }
};
</script>
