<template>
  <div class="expande-horizontal">
    <v-flex xs12>
      <div class="expande-horizontal wrap">
        <v-flex xs12>
          <div class="expande-horizontal">
            <div class="expande-horizontal centraliza">
              <div class="expande-horizontal column title-item">
                <span style="font-size: 16pt;" class="fonte-bold white--text"
                  >Dados Pessoais</span
                >
                <span class="fonte grey--text">Veja abaixo</span>
              </div>
            </div>
          </div>
        </v-flex>
        <v-form ref="usuario">
          <div class="expande-horizontal" style="flex-wrap: wrap">
            <!-- <v-flex xs12 md6>
              <v-text-field
                dense
                readonly
                filled
                :color="$theme.primary"
                label="Endereço da Carteira"
                v-model="getLoggedUser._id"
              ></v-text-field>
            </v-flex> -->

            <v-flex xs12 md6>
              <v-text-field
                dense
                filled
                dark
                :color="$theme.primary"
                label="Nome"
                v-model="getLoggedUser.name"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <v-text-field
                dense
                dark
                filled
                readonly
                :color="$theme.primary"
                label="Email"
                v-model="getLoggedUser.email"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                dense
                disabled
                dark
                filled
                :color="$theme.primary"
                label="Nome de usuário"
                v-model="getLoggedUser.nickname"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                dense
                filled
                readonly
                dark
                :color="$theme.primary"
                v-mask="['###.###.###-##']"
                label="Cpf"
                v-model="getLoggedUser.cpf"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <v-text-field
                dense
                filled
                dark
                :color="$theme.primary"
                label="Celular"
                v-model="getLoggedUser.phone"
              ></v-text-field>
            </v-flex>
            <!--       
            <v-flex xs12  md4>
              <v-text-field  dense filled :color="$theme.primary" label="Tamanho do calçado" v-model="getLoggedUser.tamanho_tenis" ></v-text-field>
            </v-flex>
            
            <v-flex xs12  md4>
              <v-text-field  dense filled :color="$theme.primary" label="sexo" v-model="getLoggedUser.sexo" ></v-text-field>
            </v-flex>
            
            <v-flex xs12  md4>
              <v-text-field  dense filled :color="$theme.primary" label="Medidas" v-model="getLoggedUser.medidas" ></v-text-field>
            </v-flex>
            
            <v-flex xs12  md4>
              <v-text-field  dense filled :color="$theme.primary" label="Tamanho da veste" v-model="getLoggedUser.tamanho_roupa" ></v-text-field>
            </v-flex>
             -->
            <v-flex class="mt-6" xs12>
              <v-btn @click="updateProfile" color="green" x-large dark>
                <span class="fonte-bold">salvar</span></v-btn
              >
            </v-flex>
          </div>
        </v-form>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  methods: {
    ...mapActions(["updateProfile"])
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  }
};
</script>
